// import React from 'react';
// import { useTranslation } from 'react-i18next';
// import { createStyles, Grid, StyleRules, WithStyles, withStyles } from '@material-ui/core';
// import { navigate } from 'gatsby';
// import i18n from 'i18next';

// import FAvatar from '../components/Avatar';
// import SubmitButton from '../components/Button/SubmitButton';
// import debug from '../utils/debug';

// const styles = (): StyleRules =>
//   createStyles({
//     root: {
//       width: 280,
//       margin: 'auto',
//       marginTop: 200,
//     },
//   });

// const IndexPage = ({ classes }: Props): React.ReactElement => {
//   debug('Loading index page');
//   const { t } = useTranslation();
//   i18n.loadNamespaces('index');

//   return (
//     <Grid container direction="row" justifyContent="center" spacing={1} className={classes.root}>
//       <Grid item>
//         <FAvatar />
//       </Grid>
//       <Grid item xs={12}>
//         <SubmitButton
//           onClick={() => {
//             navigate('/company');
//           }}
//         >
//           {/* 企业入口 */}
//           {t('index:company_entry')}
//         </SubmitButton>
//       </Grid>
//       <Grid item xs={12}>
//         <SubmitButton
//           onClick={() => {
//             navigate('/consultant');
//           }}
//         >
//           {/* 顾问入口 */}
//           {t('index:consultant_entry')}
//         </SubmitButton>
//       </Grid>
//       <Grid item xs={12}>
//         <SubmitButton
//           onClick={() => {
//             navigate('/admin');
//           }}
//         >
//           {/* 管理员入口 */}
//           {t('index:admin_entry')}
//         </SubmitButton>
//       </Grid>
//     </Grid>
//   );
// };

// export interface Props extends WithStyles<typeof styles> {
//   className?: string;
// }

// export default withStyles(styles)(IndexPage);
import React from 'react';

import Layout from '../layout/HomeLayout';
import ConsultantHomeView from '../views/consultant/home/consultantHomeView';
const HomePage = (): React.ReactElement => {
  return (
    <div>
      <Layout>
        <ConsultantHomeView />
      </Layout>
    </div>
  );
};

export default HomePage;
